<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="AssetTypeEditForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="editAssetType">
        <!-- Name -->
        <b-form-group label="AssetType Name" label-for="AssetType Name">
          <validation-provider
            #default="{ errors }"
            name="asset_type_name"
            :rules="{ regex: /^[a-zA-Z0-9\s\.\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/ }"
          >
            <b-form-input
              id="asset_type_name"
              v-model="asset_type_name"
              :state="errors.length > 0 ? false : null"
              name="asset_type_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="AssetType Description"
          label-for="AssetType Description"
        >
          <b-form-textarea
            id="asset_type_description"
            v-model="asset_type_description"
            name="asset_type_description"
            rows="3"
            max-rows="3"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          class="my-1"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Update</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      asset_type_name: "",
      asset_type_description: "",
      isloading: false,
    };
  },
  //   created: function () {
  //     // this.load();
  //   },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      // id = this.$route.params.id
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset-type/" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        this.asset_type_name = res.data.asset_type_name;
        this.asset_type_description = res.data.asset_type_description;
        // this.toastMessage(res)  // method call to method2
        // this.items = res.data.results;
      });
    },

    editAssetType: function () {
      this.$refs.AssetTypeEditForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          //call to axios
          console.log("success");
          let data = {
            asset_type_name: this.asset_type_name,
            asset_type_description: this.asset_type_description,
          };
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/asset-type/" +
              this.$route.params.id +
              "/",
          };
          var self = this;
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.asset_type_id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Updated Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = false;
              this.$router.push({ name: "AssetTypes" });
            }
          });
        }
      });
    },
  },
};
</script>
